import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { QualificationFilterService } from "src/app/services/filter/qualification-filter.service";
import { Qualification } from "../../interfaces";

@Component({
  selector: "app-qualification-filter",
  templateUrl: "./qualification-filter.component.html",
  styleUrls: ["./qualification-filter.component.scss"],
})
export class QualificationFilterComponent implements OnInit {
  @Input() set Qualifications(qualifications: Qualification[]) {
    this.qualifications = qualifications;
    this.selectedQualificationIds = this.qualificationFilterService.getFilter();
  }

  @Output() Update = new EventEmitter<string[]>();

  qualifications: Qualification[];
  selectedQualificationIds: string[];

  constructor(private qualificationFilterService: QualificationFilterService) {}

  ngOnInit() {}

  updateFilter(ids: string[]) {
    this.qualificationFilterService.setFilter(ids);
    this.Update.emit(this.selectedQualificationIds);
  }
}
