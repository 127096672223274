import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Qualification, Team } from "../../../interfaces";

@Component({
  selector: "app-schedule-filter",
  templateUrl: "./schedule-filter.component.html",
  styleUrls: ["./schedule-filter.component.scss"],
})
export class ScheduleFilterComponent implements OnInit {
  @Input() Teams: Team[];
  @Input() Qualifications: Qualification[];
  @Output() TeamUpdate = new EventEmitter<string[]>();
  @Output() QualificationUpdate = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit() {}

  teamUpdate(ids: string[]) {
    this.TeamUpdate.emit(ids);
  }

  qualificationUpdate(ids: string[]) {
    this.QualificationUpdate.emit(ids);
  }
}
