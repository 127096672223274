import { Component, ElementRef, OnInit, ViewChild, Input } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { ModalController } from "@ionic/angular";
import { WorkOrderImportService } from "../../services/import/work-order-import.service";
import { Subscription } from "rxjs";
import { WorkOrderService } from "../../services/api/work-order.service";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { InventoryService } from "../../services/api/inventory.service";
import { ArrayService } from "../../services/core/array.service";
import { WorkOrderTypeService } from "../../services/api/work-order-type.service";
import { Inventory, WorkOrder, WorkOrderImportResult, WorkOrderType } from "../../interfaces";

@Component({
  selector: "app-work-order-import",
  templateUrl: "./work-order-import.component.html",
  styleUrls: ["./work-order-import.component.scss"]
})
export class WorkOrderImportComponent implements OnInit {
  @ViewChild("FileSelect", { static: true }) FileSelect: ElementRef;
  @Input() Name: string;
  @Input() set Service(service: string) {
    this.workOrderImportService.setActiveService(service);
  }

  selectionConfig: {
    all: boolean;
    subset: boolean;
    excludeWorkOrderWithoutType: boolean; //if true, workorder without matching types will be imported without tpye, if false they will be skipped
    time: boolean; // if true time filter is evaluated
    sheet: boolean; // if true sheet filter is evaluated
    sheets: {
      // which sheets are selected for import and which rows of that sheet, only used when sheet=true
      [key: string]: {
        selected: boolean;
        from: number;
        to: number;
        range: string;
      };
    };
    times: {
      // only WorkOrders in time range are imported, only used when time=true
      from: Date;
      to: Date;
      range: { from: Date; to: Date };
    };
  };

  imports: WorkOrderImportResult = { sheets: [], rows: {}, values: {} };
  selectedImports: { sheet: string; row: string; workOrder: WorkOrder }[] = [];

  inventories: { [name: string]: Inventory };
  workOrderTypes: { [name: string]: WorkOrderType };
  workOrderTypesWithGroup: { [name: string]: WorkOrderType };

  synchronizing = false;
  loading = false;

  subscription: Subscription;

  dragAndDropHover = false;
  hasFile = false;
  hasWorkOrders = false;
  fileName: string;
  previewSettings: { Icon: any; Cols: any } = { Icon: {}, Cols: {} };

  constructor(
    private api: ApiService,
    private modalController: ModalController,
    private workOrderImportService: WorkOrderImportService,
    private workOrderService: WorkOrderService,
    private inventoryService: InventoryService,
    private arrayService: ArrayService,
    private workOrderTypeService: WorkOrderTypeService
  ) {}

  async ngOnInit() {
    this.previewSettings = await this.workOrderImportService.getPreviewSettings();
    this.workOrderTypes = this.arrayService.toMap(await this.workOrderTypeService.getAll(), "Name");
    this.workOrderTypesWithGroup = ((await this.workOrderTypeService.getAll()) || []).reduce(
      (pV: WorkOrderType, cV: WorkOrderType) => ({ ...pV, [cV.WorkOrderTypeGroup.Name + "_" + cV.Name]: cV }),
      {}
    ) as { [key: string]: WorkOrderType };
    this.inventories = this.arrayService.toMap(await this.inventoryService.getAll(), "Name");
    this.synchronizing = false;
    this.subscription = this.workOrderImportService.Parsed.subscribe(async parsedElements => this.initImports(parsedElements));
    this.hasFile = false;
    this.dragAndDropHover = false;
    this.hasWorkOrders = false;
    this.selectedImports = [];
    this.loading = false;
  }

  async initImports(data: WorkOrderImportResult) {
    this.loading = false;
    this.hasWorkOrders = false;
    this.imports = data;
    const selectAllDefault = await this.workOrderImportService.getSelectAllDefault();
    const selected = {
      all: selectAllDefault,
      excludeWorkOrderWithoutType: false,
      subset: false,
      time: false,
      sheet: false,
      sheets: {},
      times: { from: null, to: null, range: { from: null, to: null } }
    };
    let timeFrom: moment.Moment;
    let timeTo: moment.Moment;
    for (const key in this.imports.values) {
      for (const workOrder of this.imports.values[key]) {
        if (!workOrder.StartTime) {
          continue;
        }
        const date = moment(workOrder.StartTime);
        if (!timeFrom || date.isBefore(timeFrom)) {
          timeFrom = date;
        }
        if (!timeTo || date.isAfter(timeFrom)) {
          timeTo = date;
        }
      }
    }
    selected.times.from = timeFrom?.toDate() || new Date();
    selected.times.to = timeTo?.toDate() || new Date();
    selected.times.range.from = selected.times.from;
    selected.times.range.to = selected.times.to;
    const selectedSheets = {};
    for (const sheet of this.imports.sheets) {
      const sheetItem: any = {
        selected: true,
        from: Number(this.imports.rows[sheet][0]),
        to: Number(this.imports.rows[sheet][this.imports.rows[sheet].length - 1])
      };
      sheetItem.range = sheetItem.from + " - " + sheetItem.to;
      selectedSheets[sheet] = sheetItem;
    }
    selected.sheets = selectedSheets;
    this.selectionConfig = selected;
    this.hasWorkOrders = this.imports.sheets.length > 0;
    if (selectAllDefault) {
      this.selectAll();
    } else {
      this.selectNothing();
    }
  }

  selectSubset() {
    this.selectionConfig.all = false;
    this.selectionConfig.subset = true;
    this.filter();
  }

  setFromTime(newDate: any) {
    this.selectionConfig.times.from = moment(newDate).startOf("d").toDate();
    this.filter();
  }

  setToTime(newDate: any) {
    this.selectionConfig.times.to = moment(newDate).endOf("d").toDate();
    this.filter();
  }

  filter() {
    const selectedImports = [];
    for (const sheet of this.imports.sheets) {
      for (const row of this.imports.rows[sheet]) {
        const key = sheet + "-" + row;
        for (const workOrder of this.imports.values[key]) {
          if (!workOrder) {
            continue;
          }
          if (this.isSelectedBySheetFilter(sheet, Number(row)) && this.isSelectedByTimeFilter(workOrder)) {
            selectedImports.push({ sheet, row, workOrder });
          }
        }
      }
    }
    this.selectedImports = selectedImports;
  }

  isSelectedBySheetFilter(sheetName: string, row: number): boolean {
    const sheetSelection = this.selectionConfig.sheets[sheetName];
    return (
      !this.selectionConfig.subset ||
      !this.selectionConfig.sheet ||
      (sheetSelection.selected && row >= sheetSelection.from && row <= sheetSelection.to)
    );
  }

  isSelectedByTimeFilter(workOrder: WorkOrder) {
    if (!this.selectionConfig.time) {
      return true;
    } else if (!workOrder || !workOrder.StartTime) {
      return false;
    }
    const from: moment.Moment = moment(this.selectionConfig.times.from);
    const to: moment.Moment = moment(this.selectionConfig.times.to);
    const startTime = moment(workOrder.StartTime);
    return !this.selectionConfig.subset || (startTime.isSameOrAfter(from) && startTime.isSameOrBefore(to));
  }

  selectNothing() {
    for (const key in this.selectionConfig.sheets) {
      this.selectionConfig.sheets[key].selected = false;
    }
    this.selectionConfig.sheet = true;
    this.selectionConfig.time = false;
    this.selectionConfig.subset = true;
    this.selectedImports = [];
  }

  selectAll() {
    this.selectionConfig.all = true;
    this.selectionConfig.subset = false;
    this.selectionConfig.sheet = false;
    this.selectionConfig.time = false;
    const selectedImports = [];
    for (const sheet of this.imports.sheets) {
      for (const row of this.imports.rows[sheet]) {
        const key = sheet + "-" + row;
        for (const workOrder of this.imports.values[key]) {
          if (!workOrder) {
            continue;
          }
          selectedImports.push({ sheet, row, workOrder });
        }
      }
    }
    this.selectedImports = selectedImports;
  }

  async closeModal(save: boolean = true) {
    if (save) {
      await this.save();
    } else {
      await this.modalController.dismiss();
    }
  }

  openFileSelection() {
    const e: HTMLElement = this.FileSelect.nativeElement;
    e.click();
  }

  async filesDropped(files: File[]) {
    return this.parseFile({ target: { files } });
  }

  async parseFile(event: any) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      this.fileName = event.target.files[0].name;
      this.hasFile = true;
    }
    this.loading = true;
    await this.workOrderImportService.import(event);
  }

  async save() {
    this.synchronizing = true;
    const workOrders = [];
    for (const item of this.selectedImports) {
      const workOrder = item.workOrder;
      //TODO check for typegroup
      let type = null;
      if(this.typeExists(workOrder.WorkOrderType)) {
        type = workOrder.WorkOrderType.WorkOrderTypeGroup ? this.workOrderTypesWithGroup[workOrder.WorkOrderType.WorkOrderTypeGroup.Name +'_'+workOrder.WorkOrderType.Name] : this.workOrderTypes[workOrder.WorkOrderType.Name]
      }
      if (this.selectionConfig && this.selectionConfig.excludeWorkOrderWithoutType && !type) {
        continue;
      }
      const copy = JSON.parse(JSON.stringify(type || {}));
      delete copy.Id;
      delete copy.CreatedAt;
      delete copy.UpdatedAt;
      delete copy.Qualifications;
      for (const workOrderKey in workOrder) {
        if (workOrder.hasOwnProperty(workOrderKey) && workOrder[workOrderKey]) {
          copy[workOrderKey] = workOrder[workOrderKey];
        }
      }
      delete copy.WorkOrderType;
      if (type) {
        copy.WorkOrderTypeId = type.Id;
      }
      const inventories = [];
      for (const newInventory of copy.Inventories || []) {
        const inventory = this.inventories[newInventory.Name];
        if (inventory) {
          inventories.push(inventory);
        }
      }
      copy.Inventories = inventories;
      workOrders.push(copy);
    }
    const writeChangeLogFile = await this.workOrderImportService.writeChangeLogFile();
    if (writeChangeLogFile) {
      const date = moment().format("YYYY_MM_DD_HH_mm_ss");
      const blob: Blob = await this.workOrderService.importAllAndDownloadFile(workOrders);

      saveAs(blob, "import_" + date + ".xlsx");
    } else {
      await this.workOrderService.importAll(workOrders);
    }
    this.synchronizing = false;
    return this.modalController.dismiss("update");
  }

  typeExists(workOrderType: WorkOrderType): boolean {
    if(workOrderType && workOrderType.WorkOrderTypeGroup && workOrderType.WorkOrderTypeGroup.Name ) {
      return workOrderType.Name && this.workOrderTypesWithGroup[workOrderType.WorkOrderTypeGroup.Name +'_'+ workOrderType.Name] != null;
    } else {
      return workOrderType && workOrderType.Name && this.workOrderTypes[workOrderType.Name] != null;
    }
  }

  getAddress(workOrder: WorkOrder): string {
    let address = "";
    if (workOrder.Street) {
      address += workOrder.Street + " ";
    }
    if (workOrder.Zip) {
      address += workOrder.Zip + " ";
    }
    if (workOrder.City) {
      address += workOrder.City;
    }
    return address;
  }
  setTimes(range: string) {
    if (range === "year") {
      this.setFromTime(moment().startOf("year"));
      this.setToTime(moment().endOf("year"));
    } else if (range === "month") {
      this.setFromTime(moment().startOf("month"));
      this.setToTime(moment().endOf("month"));
    } else {
      this.setFromTime(this.selectionConfig.times.range.from);
      this.setToTime(this.selectionConfig.times.range.to);
    }
  }
}
