export enum Navigation {
  PREVIOUS,
  NEXT,
}
export enum LeasingAgreementStatus {
  REQUESTED = "requested",
  ACCEPTED = "accepted",
  CONFIRMED = "confirmed",
  DECLINED = "declined",
}

export enum Visibility {
  PUBLIC = "public",
  PRIVATE = "private",
  MANAGER = "manager",
}

export enum TimeslotType {
  SHIFT = "SHIFT",
  WORK_ORDER = "WORK_ORDER",
  ABSENCE = "ABSENCE",
  HOLIDAY = "HOLIDAY",
  SHIFT_TYPE = "SHIFT_TYPE",
  WORK_ORDER_TYPE = "WORK_ORDER_TYPE",
}

export enum TimeMode {
  NONE = "None",
  DAY = "Day",
  WEEK = "Week",
  MONTH = "Month",
}

export enum EmployeeDataView {
  GENERAL = "Allgemein",
  SPECIAL = "Spezialfunktionen",
}

export enum MenuItem {
  SHIFTS = "SHIFTS",
  WORK_ORDERS = "WORK_ORDERS",
  SETTINGS = "SETTINGS",
  DASHBOARD = "DASHBOARD",
  ADMIN_SETTINGS = "ADMIN_SETTINGS",
}

export enum DraftType {
  SHIFT = "shifts",
  WORK_ORDER = "work-orders",
  MASTERLIST = ",asterlistss",
}

export enum TypeGroupType {
  SHIFT = "shift-type",
  WORK_ORDER = "work-order-type",
}

export enum AbsenceStatus {
  CONFIRMED = "confirmed",
  PENDING = "pending",
  DECLINED = "declined",
  VERIFICATION_MISSING = "verification_missing",
  VERIFIED = "verified",
}

export enum FormFieldType {
  TEXT = "text",
  TEXT_STATIC = "static",
  TEXTAREA = "textarea",
  SINGLE_SELECTION = "single",
  MULTI_SELECTION = "multi",
  DATE = "date",
  DATE_STATIC = "date-static",
  TIME = "time",
  SIGNATURE = "signature",
  BOOLEAN = "boolean",
  IMAGE = "image",
  DIVIDER = "divider",
}

export enum FormTableColumnType {
  TEXT = "text",
  SINGLE_SELECTION = "single",
  MULTI_SELECTION = "multi",
  DATE = "date",
  TIME = "time",
  TEXT_STATIC = "static",
  TIMESLOT = "timeslot",
}

export enum FormChildType {
  FORM_GROUP = "FormGroup",
  FORM_TABLE = "FormTable",
}

export enum FormElementPosition {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export enum FormType {
  STATIC = "static",
  DYNAMIC = "dynamic",
}

export enum FormConditionType {
  EXACT_MATCH = "exact_match",
  ANY_MATCH = "any_match",
}

export enum UserRestriction {
  ALL = "all",
  EXTERNAL = "external",
  NOT_EXTERNAL = "permanent",
  SELECTION = "selection",
}

export enum SpecialFieldType {
  EVALUATION = "Evaluation",
  BOOLEAN = "Boolean",
  DATE = "Date",
  DEADLINE = "Deadline",
  TIME = "Time",
  SINGLE_SELECT = "SingleSelect",
  MULTI_SELECT = "MultiSelect",
  NUMBER = "Number",
  TEXT = "TextField",
  TEXTAREA = "Textarea",
  LINK = "Link",
  FILE = "File",
}

export enum FormValueListType {
  COLUMNS = "columns",
  TEXT = "text",
}

export enum ApplicationType {
  FIRST = "first",
  MANUAL = "manual",
}

export enum TimeSlotReactionType {
  ACCEPTED = "accepted",
  ACCEPTED_REMOVED = "accepted removed",
  DECLINED = "declined",
  APPLICATION = "application",
}
