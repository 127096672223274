import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  Qualification,
  Remark,
  TimeSlot,
  TimeslotElement,
} from "../../interfaces";
import { ApiService } from "../../services/api/api.service";
import { ModalController } from "@ionic/angular";
import { EmployeeSelectionModalComponent } from "../../modals/employee-selection-modal/employee-selection-modal.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-time-slot-list-item",
  templateUrl: "./time-slot-list-item.component.html",
  styleUrls: ["./time-slot-list-item.component.scss"],
})
export class TimeSlotListItemComponent implements OnInit {
  @Input() TimeSlot: TimeSlot;
  @Input() Item: TimeslotElement;
  @Input() Qualifications: Qualification[] = [];
  @Input() Disabled: boolean = false;
  @Input() Remarks: Remark[] = [];
  @Input() ShowAccept: boolean = true;
  @Input() ExternalApproval: boolean = false;
  @Input() ShowTime: boolean = false;
  @Input() ShowApplicationToggle: boolean = false;
  @Input() ShowBillingNumber: boolean = false;
  @Input() ShowFees: boolean = false;
  @Input() ShowApplications: boolean = false;
  @Input() ModalMode: boolean = false;
  @Input() Component: string = "WorkOrder";

  @Output() UserRemove = new EventEmitter<void>();
  @Output() UserChange = new EventEmitter<void>();
  @Output() RemarkChange = new EventEmitter<{ [UserId: string]: Remark[] }>();
  @Output() Remove = new EventEmitter<void>();
  @Output() Poke = new EventEmitter<void>();
  @Output() Accept = new EventEmitter<void>();
  @Output() Decline = new EventEmitter<void>();

  constructor(
    private api: ApiService,
    private modalController: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {
  }
  addTag(tag: string) {
    return tag;
}

  async checkQualification() {
    if (!!this.TimeSlot.QualificationId && !!this.TimeSlot.User) {
      if (
        !this.TimeSlot.User.Qualifications ||
        JSON.stringify(this.TimeSlot.User.Qualifications).indexOf(
          this.TimeSlot.QualificationId,
        ) === -1
      ) {
        this.TimeSlot.UserId = undefined;
        this.TimeSlot.User = undefined;
      }
    }
  }

  addNewQualification() {
    return (name: string) => this.api.post("qualifications", { Name: name });
  }

  async removeUserFromTimeSlot() {
    if (this.Disabled) {
      return;
    }
    this.TimeSlot.UserId = undefined;
    this.TimeSlot.User = undefined;
    this.TimeSlot.Cost = null;
    return this.UserRemove.emit();
  }

  async openEmployeeSelectionModal(appliedFilter: boolean = false) {
    if (this.Disabled) {
      return;
    }

    const modal = await this.modalController.create({
      componentProps: {
        TimeSlot: this.TimeSlot,
        Teams: this.Item.Teams,
        Item: this.Item,
        Component: this.Component,
        AppliedFilter: appliedFilter,
      },
      backdropDismiss: false,
      component: EmployeeSelectionModalComponent,
      cssClass: "custom-ion-modal modal-70",
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (
      response?.data?.User &&
      response?.data.User.Id !== this.TimeSlot.UserId
    ) {
      this.TimeSlot.UserId = response.data.User.Id;
      this.TimeSlot.User = response.data.User;
      this.TimeSlot.Cost = response?.data?.User?.SuitableFee?.Value || null;
      this.UserChange.emit();
    }
    if (response?.data?.Remarks) {
      this.RemarkChange.emit(response.data.Remarks);
    }
  }

  setRemarks(remarks: Remark[]) {
    this.RemarkChange.emit({ [this.TimeSlot.UserId]: remarks });
  }

  emitRemove() {
    this.Remove.emit();
  }

  emitAccept() {
    if (this.Disabled) {
      return;
    }
    this.Accept.emit();
  }
  emitDecline() {
    if (this.Disabled) {
      return;
    }
    this.Decline.emit();
  }

  async openTimeSlot() {
    if (!this.TimeSlot.Id) {
      return;
    }
    if(this.ModalMode) {
      const modal = await this.modalController.getTop();
      if(modal) {
        await modal.dismiss();
      }
    }
    await this.router.navigate(["/time-slots", this.TimeSlot.Id]);
  }

  emitPoke() {
    if (this.Disabled) {
      return;
    }
    return this.Poke.emit();
  }
}
