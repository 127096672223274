import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../../services/api/auth.service";
import { RemarkService } from "../../../services/api/remark.service";
import { ModalController } from "@ionic/angular";
import { RemarkModalComponent } from "../../../modals/remark-modal/remark-modal.component";

@Component({
  selector: "app-crud-bar-remark",
  templateUrl: "./crud-bar-remark.component.html",
  styleUrls: ["./crud-bar-remark.component.scss"],
})
export class CrudBarRemarkComponent implements OnInit {
  @Input() set Id(ItemId: string) {
    this.itemId = ItemId;
  }
  @Input() Component: string;
  @Input() Headline: string;

  count: number;
  itemId: string;

  constructor(
    private modalService: NgbModal,
    private remarkService: RemarkService,
    private authService: AuthService,
    private modalController: ModalController,
  ) {}

  async ngOnInit() {
    this.count = await this.remarkService.getCountByItemId(this.itemId);
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: RemarkModalComponent,
      componentProps: {
        ItemId: this.itemId,
        Component: this.Component,
        Headline: this.Headline,
        ShowDates: this.Component === "User" || this.Component === "Inventory",
      },
      cssClass: "modal-80",
      backdropDismiss: false,
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response && response.data && response.data.count) {
      this.count = response.data.count;
    }
  }
}
