import { Component, OnInit } from "@angular/core";
import { ScheduleStorageService } from "../../../services/storage/schedule-storage.service";
import { ScheduleStateService } from "../../../services/core/schedule-state.service";

@Component({
  selector: "app-schedule-shift-view-toggle",
  templateUrl: "./schedule-shift-view-toggle.component.html",
  styleUrls: ["./schedule-shift-view-toggle.component.scss"],
})
export class ScheduleShiftViewToggleComponent implements OnInit {
  public LIST: string = this.scheduleStateService.VIEWS.SHIFT_LIST;
  public WEEK: string = this.scheduleStateService.VIEWS.SHIFT_WEEK;
  public MONTH: string = this.scheduleStateService.VIEWS.SHIFT_MONTH;

  constructor(private scheduleStateService: ScheduleStateService) {}

  ngOnInit() {}

  async navigate(url: string) {
    return this.scheduleStateService.goToShiftView(url);
  }
}
