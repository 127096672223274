import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ScheduleStorageService {
  public VIEWS = {
    SHIFT_WEEK: "/schedule/shift-week",
    SHIFT_MONTH: "/schedule/shift-month",
    SHIFT_LIST: "/schedule/shift-list",
    WORK_ORDER_DAY: "/schedule/work-order-day",
    WORK_ORDER_WEEK: "/schedule/work-order-week",
    WORK_ORDER_MONTH: "/schedule/work-order-month",
    WORK_ORDER_LIST: "/schedule/work-order-list",
    INVENTORY_DAY: "/inventory/schedule/day",
    INVENTORY_MONTH: "/inventory/schedule/month",
    INVENTORY_LIST: "/inventory",
    ABSENCE_LIST: "/absences/list",
    WORK_TIME_LIST: "/work-times/list",
    REMARK_LIST: "/remarks/list",
    FILLED_FORMS_LIST: "/filled-forms/list",
  };

  constructor(private storageService: StorageService) { }

  private removeDate(url: string = ""): string {
    for (const key in this.VIEWS) {
      const urlWithoutParams = this.VIEWS[key];
      if ((url || "").indexOf(urlWithoutParams) > -1) {
        return urlWithoutParams;
      }
    }
    return url;
  }

  public setRemarkSchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set("last-visited-remark-schedule", urlWithoutParams);
    this.setDate(url, urlWithoutParams);
  }

  public getRemarkSchedule(sourceUrl?: string): string {
    const url = this.removeDate(
      sourceUrl || this.storageService.get("last-visited-remark-schedule"),
    );
    return url ? url + this.getDate(url) : null;
  }

  public setFilledFormsSchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set("last-visited-filled-forms", urlWithoutParams);
    this.setDate(url, urlWithoutParams);
  }

  public getFilledFormsSchedule(sourceUrl?: string): string {
    const url = this.removeDate(
      sourceUrl || this.storageService.get("last-visited-filled-forms"),
    );
    return url ? url + this.getDate(url) : null;
  }

  public setWorkTimeSchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set(
      "last-visited-work-time-schedule",
      urlWithoutParams,
    );
    this.setDate(url, urlWithoutParams);
  }

  public getWorkTimeSchedule(sourceUrl?: string): string {
    const url = this.removeDate(
      sourceUrl || this.storageService.get("last-visited-work-time-schedule"),
    );
    return url ? url + this.getDate(url) : null;
  }

  public setAbsenceSchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set("last-visited-absence-schedule", urlWithoutParams);
    this.setDate(url, urlWithoutParams);
  }

  public getAbsenceSchedule(sourceUrl?: string): string {
    const url = this.removeDate(
      sourceUrl || this.storageService.get("last-visited-absence-schedule"),
    );
    return url ? url + this.getDate(url) : null;
  }

  public setInventorySchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set(
      "last-visited-inventory-schedule",
      urlWithoutParams,
    );
    this.setDate(url, urlWithoutParams);
  }

  public getInventorySchedule(sourceUrl?: string): string {
    const url = this.removeDate(
      sourceUrl || this.storageService.get("last-visited-inventory-schedule"),
    );
    return url ? url + this.getDate(url) : null;
  }

  public setWorkOrderSchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set(
      "last-visited-work-order-schedule",
      urlWithoutParams,
    );
    this.setDate(url, urlWithoutParams);
  }
  public setMasterlistSchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set(
      "last-visited-masterlist-schedule",
      urlWithoutParams,
    );
    this.setDate(url, urlWithoutParams);
  }

  public getWorkOrderSchedule(sourceUrl?: string): string {
    const url = this.removeDate(
      sourceUrl || this.storageService.get("last-visited-work-order-schedule"),
    );
    return url ? url + this.getDate(url) : null;
  }

  public setShiftSchedule(url: string) {
    const urlWithoutParams = this.removeDate(url);
    this.storageService.set("last-visited-shift-schedule", urlWithoutParams);
    this.setDate(url, urlWithoutParams);
  }

  public getShiftSchedule(sourceUrl?: string): string {
    const url = this.removeDate(
      sourceUrl || this.storageService.get("last-visited-shift-schedule"),
    );
    return url ? url + this.getDate(url) : null;
  }

  private getDate(prefix: string): string {
    const obj = this.getDateObject();
    return obj[prefix] || "";
  }

  private setDate(url: string, prefix: string) {
    const obj = this.getDateObject();
    const date = url.substring(prefix.length);
    if (date.length === 0 || date === "/") {
      delete obj[prefix];
    } else {
      obj[prefix] = date;
    }
    this.setDateObject(obj);
  }

  private setDateObject(obj: any = {}) {
    if (Object.keys(obj).length === 0) {
      this.storageService.remove("last-visited-schedule-date");
    } else {
      this.storageService.set(
        "last-visited-schedule-date",
        JSON.stringify(obj),
      );
    }
  }

  private getDateObject() {
    const obj = this.storageService.get("last-visited-schedule-date");
    return obj ? JSON.parse(obj) : {};
  }

  public getTodayRoute(url: string): string {
    return this.removeDate(url);
  }
}
