import { ApiService } from "src/app/services/api/api.service";
import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { sanitize } from "sanitize-filename-ts";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ToastService } from "../core/toast.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor(
    private api: ApiService,
    private toastService: ToastService,
  ) {}

  async exportToXlsx(ExportData: any, Name: string) {
    try {
      if (Array.isArray(ExportData)) {
        const Data = [];
        for (const Entry of ExportData) {
          Data.push(this.flattenObject(Entry));
        }
        await this.exportSheetToXlsx(XLSX.utils.json_to_sheet(Data), Name);
      } else {
        const flattened: any = this.flattenObject(ExportData);
        await this.exportSheetToXlsx(
          XLSX.utils.json_to_sheet([flattened]),
          Name,
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  // TODO maybe replace XLSX with ExcelJS, which supports styling
  async exportSheetToXlsx(ws: any, fileName: string, sheetName?: any) {
    try {
      const wb = XLSX.utils.book_new();
      const sheets: any[] = Array.isArray(ws) ? (ws as any[]) : [ws as any];
      sheetName = sheetName || "Sheet1";
      const sheetNames: string[] =
        Array.isArray(sheetName) && sheetName.length === sheets.length
          ? (sheetName as string[])
          : [sheetName as string];
      let lastSheetName = sheetNames[0];
      for (let i = 0; i < sheets.length; i++) {
        if (!sheets[i]) {
          continue;
        }
        let currentSheetName: string;
        if (sheetNames[i]) {
          currentSheetName = sheetNames[i];
          if (currentSheetName.length > 30) {
            currentSheetName = currentSheetName.substr(0, 30);
          }
          lastSheetName = currentSheetName;
        } else {
          currentSheetName = lastSheetName;
        }
        XLSX.utils.book_append_sheet(wb, sheets[i], currentSheetName);
      }
      XLSX.writeFile(wb, this.sanitizeFileName(fileName) + ".xlsx");
    } catch (err) {
      console.log(err);
    }
  }

  private sanitizeFileName(Name: string): string {
    const saveName = Name == null || Name === "" ? "export" : Name;
    return sanitize(saveName);
  }

  flattenObject(obj: any): {} {
    const flattened = {};

    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue;
      }
      if (typeof obj[key] == "object") {
        const flatObject = this.flattenObject(obj[key]);
        for (const flatObjectKey in flatObject) {
          if (!flatObject.hasOwnProperty(flatObjectKey)) {
            continue;
          }
          flattened[key + "." + flatObjectKey] = flatObject[flatObjectKey];
        }
      } else {
        flattened[key] = obj[key];
      }
    }
    return flattened;
  }

  async exportToJson(ExportData: any[], Name: string) {
    try {
      const file = new Blob([JSON.stringify(ExportData)], {
        type: "application/json",
      });
      saveAs(file, this.sanitizeFileName(Name) + ".json");
    } catch (err) {
      console.log(err);
    }
  }

  private getPdfExportData(ExportData: any[]): {
    Table: any[];
    ColumnCount: number;
  } {
    const Data = [];
    const Header = [];
    let hasHeader = false;
    let headerAdded = false;
    for (const Entry of ExportData) {
      if (typeof Entry == "object") {
        hasHeader = true;
        const Row = [];
        for (const Key in Entry) {
          if (Entry.hasOwnProperty(Key)) {
            const value = Entry[Key];
            Row.push(typeof value == "object" ? JSON.stringify(value) : value);
            if (!headerAdded) {
              Header.push(Key);
            }
          }
        }
        if (!headerAdded) {
          Data.push(Header);
          headerAdded = true;
        }
        Data.push(Row);
      } else {
        Data.push([Entry]);
      }
    }
    if (!hasHeader) {
      Data.unshift(["Daten"]);
    }
    return {
      Table: Data,
      ColumnCount: hasHeader ? Header.length : 1,
    };
  }

  savePDF(content: any[], Name: string) {
    try {
      const documentDefinition = {
        pageSize: "A4",
        info: { title: Name },
        content,
      };
      pdfMake
        .createPdf(documentDefinition)
        .download(this.sanitizeFileName(Name) + ".pdf");
    } catch (err) {
      console.log(err);
    }
  }

  async exportToPdf(ExportData: any[], Name: string) {
    const PreparedExportData = this.getPdfExportData(ExportData);
    const content = [
      {
        table: {
          headerRows: 1,
          widths: new Array(PreparedExportData.ColumnCount).fill(
            100 / PreparedExportData.ColumnCount + "%",
          ),
          body: PreparedExportData.Table,
        },
        layout: "lightHorizontalLines",
      },
    ];
    this.savePDF(content, Name);
  }

  async downloadFile(
    Path: any,
    Params: any = {},
    Name: string,
    FileType: string = "xlsx",
  ) {
    const fileName = `${this.sanitizeFileName(Name)}.${FileType}`;
    try {
      if (FileType === "csv" || FileType === "txt") {
        return this.downFileFromText(fileName, Path, Params);
      } else {
        return this.downloadFileFromBlob(fileName, Path, Params);
      }
    } catch (e) {
      await this.toastService.presentError(
        "Die Daten können nicht exportiert werden",
      );
    }
  }

  async downFileFromText(fileName, path, params) {
    const csvContent = await this.api.getFileText(path, params);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,\uFEFF" + encodedUri,
    );
    link.setAttribute("download", fileName);
    link.click();
  }

  async downloadFileFromBlob(fileName, path, params) {
    const data = await this.api.getFileBlob(path, params);
    return saveAs(data, fileName);
  }
}
