import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { TeamFilterService } from "../../services/filter/team-filter.service";
import { Team } from "../../interfaces";

@Component({
  selector: "app-team-filter",
  templateUrl: "./team-filter.component.html",
  styleUrls: ["./team-filter.component.scss"],
})
export class TeamFilterComponent implements OnInit {
  @Input() set Teams(teams: Team[]) {
    this.teams = teams;
    this.selectedTeamIds = this.teamFilterService.getFilter();
  }
  @Output() Update = new EventEmitter<string[]>();

  teams: Team[];
  selectedTeamIds: string[];

  constructor(private teamFilterService: TeamFilterService) {}

  ngOnInit() {}

  updateTeamFilter(id: string[]) {
    this.teamFilterService.setFilter(id);
    this.Update.emit(this.selectedTeamIds);
  }
}
