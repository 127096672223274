import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: "setup",
    loadChildren: () =>
      import("./pages/setup/setup.module").then((m) => m.SetupModule),
  },
  {
    path: "selection",
    loadChildren: () =>
      import("./pages/selection/selection.module").then(
        (m) => m.SelectionModule,
      ),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./pages/signup/signup.module").then((m) => m.SignupModule),
  },
  {
    path: "work-times",
    loadChildren: () =>
      import("./pages/work-time/work-time.module").then(
        (m) => m.WorkTimeModule,
      ),
  },
  {
    path: "employees",
    loadChildren: () =>
      import("./pages/employee/employee.module").then((m) => m.EmployeeModule),
  },
  {
    path: "teams",
    loadChildren: () =>
      import("./pages/team/team.module").then((m) => m.TeamModule),
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("./pages/task/task.module").then((m) => m.TaskModule),
  },
  {
    path: "schedule",
    loadChildren: () =>
      import("./pages/schedule/schedule.module").then((m) => m.ScheduleModule),
  },
  {
    path: "time-slots",
    loadChildren: () =>
      import("./pages/time-slot/time-slot.module").then(
        (m) => m.TimeSlotModule,
      ),
  },
  {
    path: "conflicts",
    loadChildren: () =>
      import("./pages/conflict/conflict.module").then((m) => m.ConflictModule),
  },
  {
    path: "shifts",
    loadChildren: () =>
      import("./pages/shift/shift.module").then((m) => m.ShiftModule),
  },
  {
    path: "shift-types",
    loadChildren: () =>
      import("./pages/shift-type/shift-type.module").then(
        (m) => m.ShiftTypeModule,
      ),
  },
  {
    path: "break-types",
    loadChildren: () =>
      import("./pages/break-type/break-type.module").then(
        (m) => m.BreakTypeModule,
      ),
  },
  {
    path: "work-orders",
    loadChildren: () =>
      import("./pages/work-order/work-order.module").then(
        (m) => m.WorkOrderModule,
      ),
  },
  {
    path: "work-order-types",
    loadChildren: () =>
      import("./pages/work-order-type/work-order-type.module").then(
        (m) => m.WorkOrderTypeModule,
      ),
  },
  {
    path: "calendars",
    loadChildren: () =>
      import("./pages/calendar/calendar.module").then((m) => m.CalendarModule),
  },
  {
    path: "absences",
    loadChildren: () =>
      import("./pages/absence/absence.module").then((m) => m.AbsenceModule),
  },
  {
    path: "activities",
    loadChildren: () =>
      import("./pages/activity/activity.module").then((m) => m.ActivityModule),
  },
  {
    path: "inventory",
    loadChildren: () =>
      import("./pages/inventory/inventory.module").then(
        (m) => m.InventoryModule,
      ),
  },
  {
    path: "remarks",
    loadChildren: () =>
      import("./pages/remark/remark.module").then((m) => m.RemarkModule),
  },
  {
    path: "type-groups",
    loadChildren: () =>
      import("./pages/type-group/type-group.module").then(
        (m) => m.TypeGroupModule,
      ),
  },
  {
    path: "admin/terminals",
    loadChildren: () =>
      import("./pages/terminal/terminal.module").then((m) => m.TerminalModule),
  },
  {
    path: "fees",
    loadChildren: () =>
      import("./pages/fee/fee.module").then((m) => m.FeeModule),
  },
  {
    path: "admin/absence-types",
    loadChildren: () =>
      import("./pages/absence/absence-type.module").then(
        (m) => m.AbsenceTypeModule,
      ),
  },
  {
    path: "admin/absence-type-contingents",
    loadChildren: () =>
      import("./pages/absence/absence-type-contingent.module").then(
        (m) => m.AbsenceTypeContingentModule,
      ),
  },
  {
    path: "admin/invoices",
    loadChildren: () =>
      import("./pages/invoices/invoices.module").then((m) => m.InvoicesModule),
  },
  {
    path: "admin/leasing-agreements",
    loadChildren: () =>
      import("./pages/leasing-agreements/leasing-agreements.module").then(
        (m) => m.LeasingAgreementsModule,
      ),
  },
  {
    path: "admin/leasings",
    loadChildren: () =>
      import("./pages/leasings/leasings.module").then((m) => m.LeasingsModule),
  },
  {
    path: "admin/company-locations",
    loadChildren: () =>
      import("./pages/company-location/company-location.module").then(
        (m) => m.CompanyLocationModule,
      ),
  },
  {
    path: "admin/app-settings",
    loadChildren: () =>
      import("./pages/setting/setting.module").then((m) => m.SettingModule),
  },
  {
    path: "admin/push-settings",
    loadChildren: () =>
      import("./pages/push-settings/push-settings.module").then(
        (m) => m.PushSettingsModule,
      ),
  },
  {
    path: "admin/company-settings",
    loadChildren: () =>
      import("./pages/company-settings/company-settings.module").then(
        (m) => m.CompanySettingsModule,
      ),
  },
  {
    path: "admin/tariffs",
    loadChildren: () =>
      import("./pages/tariff/tariff.module").then((m) => m.TariffModule),
  },
  {
    path: "admin/employment-types",
    loadChildren: () =>
      import("./pages/employment-type/employment-type.module").then(
        (m) => m.EmploymentTypeModule,
      ),
  },
  {
    path: "admin/employments",
    loadChildren: () =>
      import("./pages/employment/employment.module").then(
        (m) => m.EmploymentModule,
      ),
  },
  {
    path: "admin/special-fields",
    loadChildren: () =>
      import("./pages/special-field/special-field.module").then(
        (m) => m.SpecialFieldModule,
      ),
  },
  {
    path: "admin/permissions",
    loadChildren: () =>
      import("./pages/permissions/permission.module").then(
        (m) => m.PermissionsModule,
      ),
  },
  {
    path: "admin/gdpr",
    loadChildren: () =>
      import("./pages/gdpr/gdpr.module").then((m) => m.GDPRModule),
  },
  {
    path: "news",
    loadChildren: () =>
      import("./pages/news/news.module").then((m) => m.NewsModule),
  },
  {
    path: "filled-forms",
    loadChildren: () =>
      import("./pages/filled-form/filled-form.module").then(
        (m) => m.FilledFormModule,
      ),
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./pages/report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "statistics",
    loadChildren: () =>
      import("./pages/statistics/statistics.module").then(
        (m) => m.StatisticsModule,
      ),
  },
  {
    path: "shift-generator",
    loadChildren: () =>
      import("./pages/shift-generator/shift-generator.module").then(
        (m) => m.ShiftGeneratorModule,
      ),
  },
  {
    path: "settings/qualifications",
    loadChildren: () =>
      import("./pages/qualifications/qualification.module").then(
        (m) => m.QualificationModule,
      ),
  },
  {
    path: "settings/interaction-forms",
    loadChildren: () =>
      import("./pages/interaction-form/interaction-form.module").then(
        (m) => m.InteractionFormModule,
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: "setup",
    loadChildren: () =>
      import("./pages/setup/setup.module").then((m) => m.SetupModule),
  },
  {
    path: "selection",
    loadChildren: () =>
      import("./pages/selection/selection.module").then(
        (m) => m.SelectionModule,
      ),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./pages/signup/signup.module").then((m) => m.SignupModule),
  },
  {
    path: "work-times",
    loadChildren: () =>
      import("./pages/work-time/work-time.module").then(
        (m) => m.WorkTimeModule,
      ),
  },
  {
    path: "employees",
    loadChildren: () =>
      import("./pages/employee/employee.module").then((m) => m.EmployeeModule),
  },
  {
    path: "teams",
    loadChildren: () =>
      import("./pages/team/team.module").then((m) => m.TeamModule),
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("./pages/task/task.module").then((m) => m.TaskModule),
  },
  {
    path: "schedule",
    loadChildren: () =>
      import("./pages/schedule/schedule.module").then((m) => m.ScheduleModule),
  },
  {
    path: "time-slots",
    loadChildren: () =>
      import("./pages/time-slot/time-slot.module").then(
        (m) => m.TimeSlotModule,
      ),
  },
  {
    path: "conflicts",
    loadChildren: () =>
      import("./pages/conflict/conflict.module").then((m) => m.ConflictModule),
  },
  {
    path: "shifts",
    loadChildren: () =>
      import("./pages/shift/shift.module").then((m) => m.ShiftModule),
  },
  {
    path: "shift-types",
    loadChildren: () =>
      import("./pages/shift-type/shift-type.module").then(
        (m) => m.ShiftTypeModule,
      ),
  },
  {
    path: "break-types",
    loadChildren: () =>
      import("./pages/break-type/break-type.module").then(
        (m) => m.BreakTypeModule,
      ),
  },
  {
    path: "work-orders",
    loadChildren: () =>
      import("./pages/work-order/work-order.module").then(
        (m) => m.WorkOrderModule,
      ),
  },
  {
    path: "work-order-types",
    loadChildren: () =>
      import("./pages/work-order-type/work-order-type.module").then(
        (m) => m.WorkOrderTypeModule,
      ),
  },
  {
    path: "calendars",
    loadChildren: () =>
      import("./pages/calendar/calendar.module").then((m) => m.CalendarModule),
  },
  {
    path: "absences",
    loadChildren: () =>
      import("./pages/absence/absence.module").then((m) => m.AbsenceModule),
  },
  {
    path: "inventory",
    loadChildren: () =>
      import("./pages/inventory/inventory.module").then(
        (m) => m.InventoryModule,
      ),
  },
  {
    path: "remarks",
    loadChildren: () =>
      import("./pages/remark/remark.module").then((m) => m.RemarkModule),
  },
  {
    path: "type-groups",
    loadChildren: () =>
      import("./pages/type-group/type-group.module").then(
        (m) => m.TypeGroupModule,
      ),
  },
  {
    path: "admin/terminals",
    loadChildren: () =>
      import("./pages/terminal/terminal.module").then((m) => m.TerminalModule),
  },
  {
    path: "admin/absence-types",
    loadChildren: () =>
      import("./pages/absence/absence-type.module").then(
        (m) => m.AbsenceTypeModule,
      ),
  },
  {
    path: "admin/invoices",
    loadChildren: () =>
      import("./pages/invoices/invoices.module").then((m) => m.InvoicesModule),
  },
  {
    path: "admin/leasing-agreements",
    loadChildren: () =>
      import("./pages/leasing-agreements/leasing-agreements.module").then(
        (m) => m.LeasingAgreementsModule,
      ),
  },
  {
    path: "admin/leasings",
    loadChildren: () =>
      import("./pages/leasings/leasings.module").then((m) => m.LeasingsModule),
  },
  {
    path: "admin/company-locations",
    loadChildren: () =>
      import("./pages/company-location/company-location.module").then(
        (m) => m.CompanyLocationModule,
      ),
  },
  {
    path: "admin/app-settings",
    loadChildren: () =>
      import("./pages/setting/setting.module").then((m) => m.SettingModule),
  },
  {
    path: "admin/push-settings",
    loadChildren: () =>
      import("./pages/push-settings/push-settings.module").then(
        (m) => m.PushSettingsModule,
      ),
  },
  {
    path: "admin/company-settings",
    loadChildren: () =>
      import("./pages/company-settings/company-settings.module").then(
        (m) => m.CompanySettingsModule,
      ),
  },
  {
    path: "admin/tariffs",
    loadChildren: () =>
      import("./pages/tariff/tariff.module").then((m) => m.TariffModule),
  },
  {
    path: "admin/employment-types",
    loadChildren: () =>
      import("./pages/employment-type/employment-type.module").then(
        (m) => m.EmploymentTypeModule,
      ),
  },
  {
    path: "admin/employments",
    loadChildren: () =>
      import("./pages/employment/employment.module").then(
        (m) => m.EmploymentModule,
      ),
  },
  {
    path: "admin/special-fields",
    loadChildren: () =>
      import("./pages/special-field/special-field.module").then(
        (m) => m.SpecialFieldModule,
      ),
  },
  {
    path: "admin/permissions",
    loadChildren: () =>
      import("./pages/permissions/permission.module").then(
        (m) => m.PermissionsModule,
      ),
  },
  {
    path: "admin/gdpr",
    loadChildren: () =>
      import("./pages/gdpr/gdpr.module").then((m) => m.GDPRModule),
  },
  {
    path: "news",
    loadChildren: () =>
      import("./pages/news/news.module").then((m) => m.NewsModule),
  },
  {
    path: "filled-forms",
    loadChildren: () =>
      import("./pages/filled-form/filled-form.module").then(
        (m) => m.FilledFormModule,
      ),
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./pages/report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "statistics",
    loadChildren: () =>
      import("./pages/statistics/statistics.module").then(
        (m) => m.StatisticsModule,
      ),
  },
  {
    path: "shift-generator",
    loadChildren: () =>
      import("./pages/shift-generator/shift-generator.module").then(
        (m) => m.ShiftGeneratorModule,
      ),
  },
  {
    path: "settings/qualifications",
    loadChildren: () =>
      import("./pages/qualifications/qualification.module").then(
        (m) => m.QualificationModule,
      ),
  },
  {
    path: "settings/interaction-forms",
    loadChildren: () =>
      import("./pages/interaction-form/interaction-form.module").then(
        (m) => m.InteractionFormModule,
      ),
  },
  {
    path: "app-version-modal",
    loadChildren: () =>
      import("./modals/app-version-modal/app-version-modal.module").then(
        (m) => m.AppVersionModalPageModule,
      ),
  },
  {
    path: 'timetable',
    loadChildren: () => import('./pages/timetable/timetable.module').then( m => m.TimetablePageModule)
  },
  {
    path: 'masterlist',
    loadChildren: () => import('./pages/masterlist/masterlist.module').then( m => m.MasterlistPageModule)
  },
  {
    path: 'masterlist/:timecode',
    loadChildren: () => import('./pages/masterlist/masterlist.module').then( m => m.MasterlistPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
