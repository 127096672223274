import { VersionService } from "../../services/core/version.service";
import { ShiftGeneratorComponent } from "../../pages/shift-generator/shift-generator.component";
import { ApiService } from "src/app/services/api/api.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MenuController, ModalController, Platform } from "@ionic/angular";
import { AuthService } from "src/app/services/api/auth.service";
import { ScheduleStateService } from "src/app/services/core/schedule-state.service";
import { GuardService } from "../../services/core/guard.service";
import { EmployeeListComponent } from "../../pages/employee/employee-list/employee-list.component";
import { TeamListComponent } from "../../pages/team/team-list/team-list.component";
import { AbsenceListComponent } from "../../pages/absence/absence-list/absence-list.component";
import { WorkTimeListComponent } from "../../pages/work-time/work-time-list/work-time-list.component";
import { StatisticsComponent } from "../../pages/statistics/statistics.component";
import { WorkOrderTypeListComponent } from "../../pages/work-order-type/work-order-type-list/work-order-type-list.component";
import { ShiftTypeListComponent } from "../../pages/shift-type/shift-type-list/shift-type-list.component";
import { TaskListComponent } from "../../pages/task/task-list/task-list.component";
import { QualificationListComponent } from "../../pages/qualifications/qualification-list/qualification-list.component";
import { InteractionFormListComponent } from "../../pages/interaction-form/interaction-form-list/interaction-form-list.component";
import { FilledFormListComponent } from "../../pages/filled-form/filled-form-list/filled-form-list.component";
import { SubscriptionService } from "../../services/core/subscription.service";
import { MenuItem } from "../../enums";
import { SettingsService } from "../../services/api/settings.service";
import { InventoryListComponent } from "../../pages/inventory/inventory-list/inventory-list.component";
import { PlatformStorageService } from "../../services/storage/platform-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VersionModalComponent } from "src/app/modals/version-modal/version-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  components = {
    Dashboard: MenuItem.DASHBOARD,
    Employee: "EmployeeListComponent",
    Team: "TeamListComponent",
    Shift: MenuItem.SHIFTS,
    WorkOrder: MenuItem.WORK_ORDERS,
    ShiftGenerator: "ShiftGeneratorComponent",
    Timetable: "TimetablePage",
    Absence: "AbsenceListComponent",
    WorkTime: "WorkTimeListComponent",
    Inventory: "InventoryListComponent",
    Activity: "ActivityComponent",
    InventoryMonth: "InventoryScheduleMonthComponent",
    Statistics: "StatisticsComponent",
    Settings: MenuItem.SETTINGS,
    WorkOrderType: "WorkOrderTypeListComponent",
    ShiftType: "ShiftTypeListComponent",
    Calendar: "CalendarListComponent",
    Task: "TaskListComponent",
    Qualification: "QualificationListComponent",
    InteractionForm: "InteractionFormListComponent",
    FilledInteractionForm: "FilledFormListComponent",
    AdminSettings: MenuItem.ADMIN_SETTINGS,
    MasterList: "MasterlistPage"
  };

  permission = {};

  showGeneralSettings = false;
  showMasterListSettings = false;
  showAdminSettings = false;
  showShiftGenerator = false;
  showTimetable = false;
  showInventory = false;
  showLeasing = false;
  showTerminals = false;
  showFees = false;
  ShowLanguageSelector = false;
  showActivities = false;
  showMasterlist = false;
  subscriptionKey: string;
  loading = false;
  version = null;
  masterlistActive = false;
  selectableLanguages = [
    {
      id: "de",
      name: "german",
      icon: "🇩🇪"
    },
    {
      id: "en",
      name: "english",
      icon: "🇺🇸"
    },
    {
      id: "fr",
      name: "franzsisch",
      icon: "🇫🇷"
    },
    {
      id: "it",
      name: "italienisch",
      icon: "🇮🇹"
    },
    {
      id: "es",
      name: "spanisch",
      icon: "🇪🇸"
    },
    {
      id: "sq",
      name: "albanian",
      icon: "🇦🇱"
    },
    {
      id: "pl",
      name: "polish",
      icon: "🇵🇱"
    },
    {
      id: "ja",
      name: "japanese",
      icon: "🇯🇵"
    }
  ];
  language;

  constructor(
    public authService: AuthService,
    public visitedService: ScheduleStateService,
    public menuCtrl: MenuController,
    private accessService: GuardService,
    private subscriptionService: SubscriptionService,
    private settingsService: SettingsService,
    private platformStorageService: PlatformStorageService,
    private modalController: ModalController,
    private translate: TranslateService
  ) {
    this.language = localStorage.getItem("chosenLanguage") || "de";
    if (window.location.href.indexOf("masterlist") !== -1) {
      this.openMasterlist();
    }
  }

  openMasterlist() {
    this.masterlistActive = true;
  }

  closeMasterlist() {
    this.masterlistActive = false;
  }

  getlanguages() {
    const languageActionSheetButtons = [];
    for (const key in this.selectableLanguages) {
      if (Object.prototype.hasOwnProperty.call(this.selectableLanguages, key)) {
        const element = this.selectableLanguages[key];
        languageActionSheetButtons.push({
          text: element.icon + " " + this.translate.instant(element.name),
          htmlAttributes: {
            "aria-label": this.translate.instant(element.name)
          },
          data: {
            language: element.id
          }
        });
      }
    }
    return languageActionSheetButtons;
  }

  async setLanguage(language: any) {
    if (language?.detail?.data?.language) {
      language = language?.detail?.data?.language || language;
      console.log(language);
      localStorage.setItem("chosenLanguage", language);
      this.translate.use(language);
    }
  }

  async ngOnInit() {
    this.loading = true;
    this.version = null;
    this.subscriptionKey = this.subscriptionService.getKey();
    await this.authService.initUser();
    await this.initPermissions();
    this.showFees = await this.settingsService.getBooleanValue("UseFees");
    this.showShiftGenerator = await this.settingsService.getBooleanValue("ShiftGenerator");
    this.showTimetable = await this.settingsService.getBooleanValue("Timetable");
    this.showActivities = await this.settingsService.getBooleanValue("UseUserActivities");
    this.showInventory = await this.settingsService.getBooleanValue("UseMaterials");
    this.showLeasing = await this.settingsService.getBooleanValue("UseLeasing");
    this.ShowLanguageSelector = await this.settingsService.getBooleanValue("ShowLanguageSelector");
    this.showMasterlist = await this.settingsService.getBooleanValue("showMasterlist");
    this.showTerminals = await this.settingsService.getBooleanValue("UseTerminals");
    const menuSubscription = this.accessService.MenuItems.subscribe(async () => await this.initPermissions());
    const platformSubscription = this.platformStorageService.Update.subscribe(() => this.updateVersion());
    this.subscriptionService.add(this.subscriptionKey, menuSubscription, platformSubscription);
    this.updateVersion();
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptionService.unsubscribeAll(this.subscriptionKey);
  }

  updateVersion() {
    this.version = this.platformStorageService.getVersion() || null;
  }

  async initPermissions() {
    const newPermissions = {};
    for (const key in this.components) {
      if (this.components.hasOwnProperty(key)) {
        const menuPermission = (await this.accessService.getMenuItemByComponentName(this.components[key])) || { _access: true };
        const modelPermission = this.authService.hasPermission(key, "Read");
        newPermissions[key] = {
          component: menuPermission._access,
          model: modelPermission
        };
      }
    }
    if (!this.authService.isBeta()) {
      newPermissions["Statistics"].model = false;
    }
    if (!this.authService.isAdmin()) {
      newPermissions["AdminSettings"].model = false;
    }
    this.permission = newPermissions;
  }

  toggleGeneralSettings() {
    this.showGeneralSettings = !this.showGeneralSettings;
    this.menuCtrl.open();
  }

  toggleAdminSettings() {
    this.showAdminSettings = !this.showAdminSettings;
    this.menuCtrl.open();
  }

  hasPermission(key: string) {
    const modelPermission = this.permission[key] && this.permission[key].hasOwnProperty("model") ? this.permission[key].model : true;
    const menuPermission = this.permission[key] && this.permission[key].hasOwnProperty("component") ? this.permission[key].component : true;
    return modelPermission && menuPermission;
  }
  async openVersionModal() {
    const modal = await this.modalController.create({
      componentProps: {},
      component: VersionModalComponent,
      cssClass: "holiday-import-modal"
    });
    await modal.present();
  }
}
