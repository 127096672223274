
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgChartsModule } from "ng2-charts";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ColorPickerModule } from "ngx-color-picker";
import { NgScrollbarModule } from "ngx-scrollbar";
import { MomentModule } from "ngx-moment";
import { QualificationFilterComponent } from "./components/qualification-filter/qualification-filter.component";
import { CreatedHintComponent } from "./components/created-hint/created-hint.component";
import { TriggerComponent } from "./components/trigger/trigger.component";
import { TimepickerComponent } from "./components/timepicker/timepicker.component";
import { CreateWorkOrderButtonComponent } from "./components/create-work-order-button/create-work-order-button.component";
import { CreateShiftButtonComponent } from "./components/create-shift-button/create-shift-button.component";
import { TimeDisplayComponent } from "./components/time-display/time-display.component";
import { HeadComponent } from "./layouts/head/head.component";
import { UserInfoComponent } from "./layouts/user-info/user-info.component";
import { DefaultFilterComponent } from "./components/default-filters/default-filter/default-filter.component";
import { FilterButtonComponent } from "./components/filter-button/filter-button.component";
import { OrderTableHeadComponent } from "./components/order-table-head/order-table-head.component";
import { DeleteModalComponent } from "./components/delete-modal/delete-modal.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { DynamicDefaultComponent } from "./components/dynamic-default/dynamic-default.component";
import { DefaultFilterTimeComponent } from "./components/default-filters/default-filter-time/default-filter-time.component";
import { DefaultFilterTimeSwitchComponent } from "./components/default-filters/default-filter-time-switch/default-filter-time-switch.component";
import { StatisticDisplayComponent } from "./components/statistic-display/statistic-display.component";
import { ExportComponent } from "./components/export/export.component";
import { PagePreviewComponent } from "./components/page-preview/page-preview.component";
import { SocketBarComponent } from "./components/socket-bar/socket-bar.component";
import { DatepickerComponent } from "./components/datepicker/datepicker.component";
import { WorkTimeTableComponent } from "./components/work-time-table/work-time-table.component";
import { BirthdayComponent } from "./components/birthday/birthday.component";
import { TeamFilterComponent } from "./components/team-filter/team-filter.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";
import { ImportComponent } from "./components/import/import.component";
import { WorkOrderImportComponent } from "./components/work-order-import/work-order-import.component";
import { MasterlistImportComponent } from "./pages/masterlist/masterlist-import/masterlist-import.component";
import { DirectiveModule } from "./directives/directive.module";
import { TranslateModule } from "@ngx-translate/core";
import { DashboardMapComponent } from "./components/dashboard/map/map.component";
import { DashboardDeadlineComponent } from "./components/dashboard/deadline/deadline.component";
import { SpecialFieldsComponent } from "./components/special-fields/special-fields.component";
import { ShiftDetailContentComponent } from "./components/shift-detail-content/shift-detail-content.component";
import { CrudBarMailComponent } from "./components/crud-bar/crud-bar-mail/crud-bar-mail.component";
import { CrudBarComponent } from "./components/crud-bar/crud-bar/crud-bar.component";
import { CrudBarLogComponent } from "./components/crud-bar/crud-bar-log/crud-bar-log.component";
import { CrudBarRemarkComponent } from "./components/crud-bar/crud-bar-remark/crud-bar-remark.component";
import { CrudToolBarComponent } from "./components/crud-bar/crud-tool-bar/crud-tool-bar.component";
import { ScheduleFilterComponent } from "./components/schedule/schedule-filter/schedule-filter.component";
import { ScheduleColorsComponent } from "./components/schedule/schedule-colors/schedule-colors.component";
import { ScheduleWorkOrderViewToggleComponent } from "./components/schedule/schedule-work-order-view-toggle/schedule-work-order-view-toggle.component";
import { ScheduleNavigateToTodayComponent } from "./components/schedule/schedule-navigate-to-today/schedule-navigate-to-today.component";
import { ScheduleShiftViewToggleComponent } from "./components/schedule/schedule-shift-view-toggle/schedule-shift-view-toggle.component";
import { WorkOrderDetailContentComponent } from "./components/work-order-detail-content/work-order-detail-content.component";
import { CollapseAllButtonComponent } from "./components/collapse-all-button/collapse-all-button.component";
import { EmployeeBadgeComponent } from "./components/employee-badge/employee-badge.component";
import { EmployeeDropdownBadgeComponent } from "./components/employee-dropdown-badge/employee-dropdown-badge.component";
import { TeamDetailContentComponent } from "./components/team-detail-content/team-detail-content.component";
import { AppSettingsComponent } from "./components/app-settings/app-settings.component";
import { InventoryDetailContentComponent } from "./components/inventory-detail-content/inventory-detail-content.component";
import { LiveOrderTableHeadComponent } from "./components/live-order-table-head/live-order-table-head.component";
import { PipesModule } from "./pipes/pipes.module";
import { AbsenceViewToggleComponent } from "./components/absence-view-toggle/absence-view-toggle.component";
import { AngularSplitModule } from "angular-split";
import { MaterialTypeFilterComponent } from "./components/material-type-filter/material-type-filter.component";
import { DisplaySettingsModalComponent } from "./modals/display-settings-modal/display-settings-modal.component";
import { ScheduleUserRemarkComponent } from "./components/schedule/schedule-user-remark/schedule-user-remark.component";
import { RemarkDetailContentComponent } from "./components/remark-detail-content/remark-detail-content.component";
import { TimeSlotUserRemarkComponent } from "./components/time-slot-user-remark/time-slot-user-remark.component";
import { StartEndTimePickerComponent } from "./components/start-end-time-picker/start-end-time-picker.component";
import { ScheduleOptionsButtonComponent } from "./components/schedule-options-button/schedule-options-button.component";
import { ScheduleTimeSlotNameComponent } from "./components/schedule/schedule-time-slot-name/schedule-time-slot-name.component";
import { UserNameComponent } from "./components/user-name/user-name.component";
import { ScheduleInventoryViewToggleComponent } from "./components/schedule/schedule-inventory-view-toggle/schedule-inventory-view-toggle.component";
import { ScheduleInventoryBadgeComponent } from "./components/schedule/schedule-inventory-badge/schedule-inventory-badge.component";
import { ScheduleInventoryButtonGroupComponent } from "./components/schedule/schedule-inventory-button-group/schedule-inventory-button-group.component";
import { TypeGroupDetailContentComponent } from "./components/type-group-detail-content/type-group-detail-content.component";
import { WorkOrderTypeDetailContentComponent } from "./components/work-order-type-detail-content/work-order-type-detail-content.component";
import { ShiftTypeDetailContentComponent } from "./components/shift-type-detail-content/shift-type-detail-content.component";
import { SearchbarComponent } from "./components/searchbar/searchbar.component";
import { BadgeRowComponent } from "./components/badge-row/badge-row.component";
import { EmployeeSubHeaderComponent } from "./components/employee-sub-header/employee-sub-header.component";
import { TriggerDetailComponent } from "./components/trigger-detail/trigger-detail.component";
import { PdfViewerComponent } from "./components/pdf-viewer/pdf-viewer.component";
import { FilledInteractionFormListComponent } from "./components/filled-interaction-form-list/filled-interaction-form-list.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { BreakTypeDetailContentComponent } from "./components/break-type-detail-content/break-type-detail-content.component";
import { TerminalDetailContentComponent } from "./components/terminal-detail-content/terminal-detail-content.component";
import { TimeSlotListItemComponent } from "./components/time-slot-list-item/time-slot-list-item.component";
import { TimeSlotListComponent } from "./components/time-slot-list/time-slot-list.component";
import { TimeSlotApplicationIndicatorComponent } from "./components/time-slot-application-indicator/time-slot-application-indicator.component";
import { DashboardOpenWorkordersComponent } from "./components/dashboard/open-workorders/open-workorders.component";
import { DashboardLogsComponent } from "./components/dashboard/logs/logs.component";
import { EmploymentTimeSelectionModalComponent } from "./modals/employment-time-selection-modal/employment-time-selection-modal.component";
import { TimeSlotRemarkComponent } from "./components/time-slot-remark/time-slot-remark.component";
import { LeasingImportComponent } from "./components/leasing-import/leasing-import.component";
import { QuillModule } from "ngx-quill";
import { EmergencyContactModalComponent } from "./modals/emergency-contact-modal/emergency-contact-modal.component";
import { ScheduleDayComponent } from "./components/schedule/schedule-day/schedule-day.component";
import { DashboardEmployeeOverviewComponent } from "./components/dashboard/employee-overview/employee-overview.component";
import { MasterlistDetailChangesComponent } from "./components/masterlist-detail-changes/masterlist-detail-changes.component";
import { CrudBarMasterlistLogComponent } from "./components/crud-bar/crud-bar-masterlist-log/crud-bar-masterlist-log.component";

const imports = [
  NgChartsModule,
  ColorPickerModule,
  CommonModule,
  DirectiveModule,
  DragDropModule,
  FormsModule,
  IonicModule,
  NgbModule,
  NgScrollbarModule,
  NgSelectModule,
  PdfViewerModule,
  RouterModule,
  TranslateModule,
  PipesModule,
  QuillModule
];

const declarations = [
  EmploymentTimeSelectionModalComponent,
  ScheduleDayComponent,
  EmergencyContactModalComponent,
  AbsenceViewToggleComponent,
  AppSettingsComponent,
  BadgeRowComponent,
  BirthdayComponent,
  BreakTypeDetailContentComponent,
  CollapseAllButtonComponent,
  ColorPickerComponent,
  CreatedHintComponent,
  CreateShiftButtonComponent,
  CreateWorkOrderButtonComponent,
  CrudBarMailComponent,
  CrudBarComponent,
  CrudBarLogComponent,
  CrudBarRemarkComponent,
  CrudToolBarComponent,
  DatepickerComponent,
  DeleteModalComponent,
  DefaultFilterComponent,
  DefaultFilterTimeComponent,
  DefaultFilterTimeSwitchComponent,
  DisplaySettingsModalComponent,
  ScheduleOptionsButtonComponent,
  DynamicDefaultComponent,
  EmployeeBadgeComponent,
  EmployeeDropdownBadgeComponent,
  EmployeeSubHeaderComponent,
  ExportComponent,
  FileUploadComponent,
  FilledInteractionFormListComponent,
  FilterButtonComponent,
  HeadComponent,
  InventoryDetailContentComponent,
  ImportComponent,
  LiveOrderTableHeadComponent,
  DashboardMapComponent,
  DashboardDeadlineComponent,
  DashboardOpenWorkordersComponent,
  DashboardLogsComponent,
  MaterialTypeFilterComponent,
  OrderTableHeadComponent,
  PagePreviewComponent,
  PaymentComponent,
  PdfViewerComponent,
  RemarkDetailContentComponent,
  ScheduleColorsComponent,
  ScheduleFilterComponent,
  ScheduleInventoryBadgeComponent,
  ScheduleInventoryButtonGroupComponent,
  ScheduleInventoryViewToggleComponent,
  ScheduleNavigateToTodayComponent,
  ScheduleTimeSlotNameComponent,
  ScheduleUserRemarkComponent,
  ScheduleWorkOrderViewToggleComponent,
  ScheduleShiftViewToggleComponent,
  SearchbarComponent,
  ShiftDetailContentComponent,
  ShiftTypeDetailContentComponent,
  SocketBarComponent,
  SpecialFieldsComponent,
  StartEndTimePickerComponent,
  StatisticDisplayComponent,
  TeamDetailContentComponent,
  TeamFilterComponent,
  TerminalDetailContentComponent,
  TimeDisplayComponent,
  TimepickerComponent,
  TimeSlotApplicationIndicatorComponent,
  TimeSlotListComponent,
  TimeSlotListItemComponent,
  TimeSlotUserRemarkComponent,
  TimeSlotRemarkComponent,
  TriggerComponent,
  TriggerDetailComponent,
  TypeGroupDetailContentComponent,
  QualificationFilterComponent,
  UserInfoComponent,
  UserNameComponent,
  WelcomeComponent,
  WorkOrderDetailContentComponent,
  WorkOrderImportComponent,
  MasterlistImportComponent,
  LeasingImportComponent,
  WorkOrderTypeDetailContentComponent,
  WorkTimeTableComponent,
  BreakTypeDetailContentComponent,
  TerminalDetailContentComponent,
  DashboardEmployeeOverviewComponent,
  MasterlistDetailChangesComponent,
  CrudBarMasterlistLogComponent
];
@NgModule({
  declarations,
  imports: [
    ...imports,
    MomentModule.forRoot({ relativeTimeThresholdOptions: {} }),
    AngularSplitModule,
    ClipboardModule,
  ],
  exports: [...imports, MomentModule, ...declarations],
})
export class AppComponentModule { }
