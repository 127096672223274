import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ExportService } from "../../services/export/export.service";
import { CustomExportSetting } from "../../interfaces";
import { Platform } from "@ionic/angular";
import { PlatformStorageService } from "../../services/storage/platform-storage.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-export",
  templateUrl: "./export.component.html",
  styleUrls: ["./export.component.scss"],
})
export class ExportComponent implements OnInit, OnDestroy {
  @Input() exportAs: { pdf?: boolean; xlsx?: boolean; json?: boolean };
  @Input() AdditionalExports: { Key: string; Name: string }[] = [];
  @Input() Name: string;
  @Input() ExportData: any;
  @Input() Emit: { pdf?: boolean; xlsx?: boolean; json?: boolean };
  @Input() CustomExports: CustomExportSetting[] = [];
  @Input() ContainerClasses = "btn-group me-3";

  @Output() Save: EventEmitter<any> = new EventEmitter(true);

  isTablet: boolean = true;
  subscription: Subscription;

  constructor(
    private exportService: ExportService,
    private platformService: PlatformStorageService,
  ) {
    this.exportAs =
      typeof this.exportAs === "undefined"
        ? {
            pdf: false,
            xlsx: true,
            json: true,
          }
        : this.exportAs;
  }

  ngOnInit() {
    this.isTablet = this.platformService.getIsTabled();
    this.subscription = this.platformService.Update.subscribe(
      () => (this.isTablet = this.platformService.getIsTabled()),
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get XlsxVisible(): boolean {
    return (
      (this.exportAs.xlsx && this.ExportData) || (this.Emit && this.Emit.xlsx)
    );
  }

  get PdfVisible(): boolean {
    return (
      (this.exportAs.pdf && this.ExportData) || (this.Emit && this.Emit.pdf)
    );
  }

  get JsonVisible(): boolean {
    return (
      (this.exportAs.json && this.ExportData) || (this.Emit && this.Emit.json)
    );
  }

  async exportToXlsx() {
    if (this.Emit && this.Emit.xlsx) {
      await this.Save.emit("xlsx");
    } else {
      await this.exportService.exportToXlsx(this.ExportData, this.Name);
    }
  }

  async exportToJson() {
    if (this.Emit && this.Emit.json) {
      await this.Save.emit("json");
    } else {
      await this.exportService.exportToJson(this.ExportData, this.Name);
    }
  }

  async downloadCustom(setting: CustomExportSetting) {
    const fileName = setting.FileName || this.Name;
    if (setting.intercept) {
      const onDismiss = async (abort: boolean, additionalParam?: string) => {
        if (abort) {
          return;
        }
        await this.exportService.downloadFile(
          setting.Path +
            (additionalParam && additionalParam.length > 0
              ? "/" + additionalParam
              : ""),
          setting.QueryParams,
          fileName,
          setting.Type,
        );
      };
      await setting.intercept(setting.Params, setting.QueryParams, onDismiss);
    } else {
      return this.exportService.downloadFile(
        setting.Path,
        setting.QueryParams,
        fileName,
        setting.Type,
      );
    }
  }

  async exportToPdf() {
    if (this.Emit && this.Emit.pdf) {
      await this.Save.emit("pdf");
    } else {
      await this.exportService.exportToPdf(this.ExportData, this.Name);
    }
  }

  async exportAdditional(key: string) {
    return this.Save.emit(key);
  }
}
