import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { StorageService } from "../storage/storage.service";
import { SortService } from "../core/sort.service";
import { ToastService } from "../core/toast.service";
import { BooleanSetting, Setting } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private sortService: SortService,
    private toastService: ToastService,
    private translate: TranslateService
  ) { }

  async get(id: string): Promise<Setting> {
    const setting = await this.api.get("settings/" + id);
    return this.toBooleanSetting(setting);
  }

  async getAllDefaults(type: string): Promise<Setting[]> {
    return this.api.get("settings/default", { Type: type });
  }

  async getAllStringValues(key: string): Promise<string[]> {
    const result = await this.getAllByKey(key);
    return result && result.length > 0 ? result.map((r) => r.Value) : [];
  }

  async getStringValue(key: string): Promise<string> {
    const setting = await this.getByKey(key);
    return setting ? setting.Value || "" : "";
  }

  async getBooleanValue(key: string): Promise<boolean> {
    return (await this.getBooleanSetting(key))?.Value || false;
  }

  async getBooleanSetting(key: string): Promise<BooleanSetting> {
    const setting = await this.getByKey(key);
    return this.toBooleanSetting(setting);
  }

  private toBooleanSetting(setting: Setting): BooleanSetting {
    if (!setting || setting.Type !== "boolean") {
      return setting;
    }
    setting.Value = setting.Value === "true";
    return setting;
  }

  async getAllByKey(key: string): Promise<Setting[]> {
    return this.api.get(`settings/by-key/?key=${key}`);
  }

  async getByKey(key: string): Promise<Setting> {
    return this.api.get(`settings/by-key/${key}`);
  }

  public async getAll(
    type?: string,
    withDefaults: boolean = true,
    useCache: boolean = false,
  ): Promise<Setting[]> {
    let settings: Setting[] = useCache
      ? this.api.Cache("settings", [])
      : (await this.api.get("settings")) || [];
    if (type) {
      settings = settings.filter((s) => s.Type === type);
    }
    if (type === "boolean") {
      settings.forEach((setting) => this.toBooleanSetting(setting));
    }
    if (type && withDefaults) {
      await this.addDefaults(settings, type);
    }
    this.sortService.sortAscByFields(settings, "Key", "Value");
    return settings;
  }

  private async addDefaults(settings: Setting[], type: string) {
    const defaults: Setting[] = (await this.getAllDefaults(type)) || [];
    for (const defaultSetting of defaults) {
      let matchFound = false;
      for (const setting of settings) {
        if (
          defaultSetting.Key === setting.Key &&
          (!defaultSetting.Value || defaultSetting.Value === setting.Value)
        ) {
          matchFound = true;
          break;
        }
      }
      if (!matchFound) {
        settings.push(defaultSetting);
      }
    }
  }

  async create(
    key: string,
    value: any,
    description: string,
    type: string = "boolean",
  ) {
    return this.save({
      Key: key,
      Value: value,
      Description: description,
      Type: type,
      CompanyId: this.storageService.getCompanyId(),
    });
  }

  async delete(setting: Setting) {
    return this.api.delete("settings/" + setting.Id);
  }

  async save(setting: Setting): Promise<Setting> {
    return this.api.post(
      "settings" + (setting.Id ? "/" + setting.Id : ""),
      setting,
    );
  }

  async saveAll(settings: Setting[]) {
    for (const setting of settings) {
      if (setting.Default && !setting.Value) {
        continue;
      }
      await this.save(setting);
    }
    return this.toastService.presentSuccess(this.translate.instant('speichern_erfolgreich'));
  }
}
