import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment/moment";
import { Router } from "@angular/router";
import { DO, Nameable, Remark, User } from "../../../interfaces";

@Component({
  selector: "app-schedule-user-remark",
  templateUrl: "./schedule-user-remark.component.html",
  styleUrls: ["./schedule-user-remark.component.scss"],
})
export class ScheduleUserRemarkComponent implements OnInit {
  @Input() set Remarks(remarks: Remark[]) {
    this.remarks = remarks;
    this.count = remarks ? remarks.length : 0;
    this.previewText = remarks && remarks.length > 0 ? remarks[0].Text : "";
  }
  @Input() User: User;
  @Input() Item: Nameable & DO;
  @Input() Day: any;
  @Input() Component: string = "User";
  @Input() Disabled: boolean = false;

  remarks: Remark[];
  count: number;
  previewText: string;

  constructor(private router: Router) {}

  ngOnInit() {}

  async open() {
    if (this.Disabled) {
      return;
    }
    const navigation = ["/remarks", "detail"];
    if (this.Component) {
      navigation.push(this.Component);
    }
    if (this.User?.Id || this.Item?.Id) {
      navigation.push(this.User?.Id || this.Item?.Id);
    }
    if (this.Day) {
      navigation.push(moment(this.Day).format("YYYY-MM-DD"));
    }
    return this.router.navigate(navigation);
  }
}
