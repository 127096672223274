import { ApiService } from "src/app/services/api/api.service";
import { Injectable } from "@angular/core";
import { ToastService } from "./toast.service";
import { PlatformStorageService } from "../storage/platform-storage.service";

@Injectable({
  providedIn: "root",
})
export class VersionService {
  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private platformStorage: PlatformStorageService,
  ) {}

  async checkVersion() {
    if (!this.platformStorage.getIsTabled()) {
      let localVersion = this.platformStorage.getVersion();
      let apiVersion = await this.apiService.get("manager-version");
      if (apiVersion !== localVersion) {
        const buttons = [
          {
            text: "Ok",
            handler: async () => await this.reNewVersion(apiVersion),
          },
          {
            text: "Abbrechen",
            role: "cancel",
            handler: async () => {},
          },
        ];
        return this.toastService.present(
          "Es wurde eine neue Version geladen",
          null,
          { buttons, duration: null },
        );
      }
    }
  }

  async reNewVersion(newVersion: string) {
    this.platformStorage.setVersion(newVersion);
    location.replace("/");
  }
}
